import Cookies from 'js-cookie';

const TOKEN_KEY = 'token';

// Remove the Bearer prefix from the token
export function removeBearerPrefix(token) {
    return token.replace('Bearer ', '');
}

// Get the token from cookies
export function getToken() {
    return Cookies.get(TOKEN_KEY);
}

// Save token in cookies
export function setToken(token) {
    Cookies.set(TOKEN_KEY, token, { secure: true, sameSite: 'strict' });
}

// Remove token from cookies
export function removeToken() {
    Cookies.remove(TOKEN_KEY);
}
