import axios from 'axios';
import { handleError } from './router'; 
import { getToken, setToken, removeBearerPrefix } from '@/helpers/authHelpers';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Request Interceptor: Attach Token to All Requests
axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor: Handle New Token (if provided)
axios.interceptors.response.use(
  (response) => {
    let token = response.headers.authorization;
    if (token) {
      let accessToken = removeBearerPrefix(token);
      setToken(accessToken); // Store token for future use
    }
    return response;
  },
  (error) => {
    handleError(error);
    return Promise.reject(error);
  }
);

// Set Authorization Header on Load (for Refresh Handling)
const storedToken = getToken();
if (storedToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
}

export default axios;